import React, {useState, useEffect} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Modal from 'react-modal';
import {useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle, faLock} from '@fortawesome/free-solid-svg-icons';
import Scanner from '../Components/Scanner/Scanner';
import ScannerHeader from '../Components/Header/ScannerHeader';
// import Chart from '../Components/Chart/Chart';
import TradingViewWidget from '../Components/Chart/TradingViewWidget';
import OptionView from '../Components/PopOut/OptionView';
import ReportView from '../Components/PopOut/ReportView';
import {join, setupGroups} from '../Helpers/formatData';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    //   maxHeight: '60%',
    padding: '30px 20px',
    height: '85%',
    width: '90%',
  },
};

const customStylesOptions = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    //   maxHeight: '60%',
    padding: '20px',
    height: 'auto',
    width: '50%',
  },
};

const customStylesLocked = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    //   maxHeight: '60%',
    padding: '20px',
    height: 'auto',
    width: '50%',
  },
};

const customStylesUpcoming = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    //   maxHeight: '60%',
    padding: '20px',
    height: 'auto',
    width: '50%',
  },
};

const customStylesReports = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    //   maxHeight: '60%',
    padding: '20px',
    height: 'auto',
    width: '75%',
  },
};

const TabView = ({
  scannersData,
  groupCollection,
  groups,
  dispatch,
  theme,
  setTheme,
  wsSocket,
  loadingGroups,
  modalIsOpen,
  userData,
  showChart,
  closeChart,
  symbolData,
  chartSettings,
  getSymbolData,
  symbolToChart,
  closeModal,
  showOptionsModal,
  openOptionsModal,
  closeOptionsModal,
  optionToView,
  showReportModal,
  openReportModal,
  closeReportModal,
  reportToView,
  groupToChart,
  showLockedModal,
  openLockedModal,
  closeLockedModal,
  showUpcomingModal,
  openUpcomingModal,
  closeUpcomingModal,
  scannerLayout,
  hasMainReport,
  reports,
  inView,
  currentDate,
}) => {
  if (!groupCollection || !Object.keys(groupCollection || {}).length || !groups?.length || loadingGroups) return null;
  const params = useParams();
  const [scannerTitle, setScannerTitle] = useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const [updatingDate, setUpdatingDate] = useState(false);
  const [allTabs, setAllTabs] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [loadingTab, setLoadingTab] = useState(true);
  const [groupInView, setGroupInView] = useState(null);

  useEffect(() => {
    const scannerId = params?.id;
    const scannerData = scannersData?.find((s) => s.slug === scannerId);
    if (!scannerData) return;
    if (scannerData?.groups?.length) {
      const filtered = scannerData?.groups.filter((g) => g.type !== 'report' && !g?.hideTab);
      const setGroups = setupGroups(scannerId, scannersData);
      setAllTabs(filtered);
      setAllGroups(setGroups);
    }
    setScannerTitle(scannerData?.title ?? '');
  }, [params, scannersData]);

  useEffect(() => {
    if (!allTabs?.length) return;
    const currentGroup = allTabs?.[currentTab];
    if (!currentGroup) return;
    setGroupInView(currentGroup);
  }, [allTabs, currentTab]);

  useEffect(() => {
    if (currentTab !== inView && allGroups?.length) {
      const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
      const today = join(new Date(), acc, '-');

      const currentGroup = allTabs?.[currentTab];
      const newGroup = allTabs?.[inView];
      if (!currentGroup) return;
      if (!newGroup) return;
      const {report, mainReport, riskReward} = currentGroup?.report ?? {report: null, mainReport: null, riskReward: null};
      const toLeave = [currentGroup?.group];
      if (report) {
        toLeave.push(report);
      }
      if (mainReport) {
        toLeave.push(mainReport);
      }
      if (riskReward) {
        toLeave.push(riskReward);
      }
      if (toLeave?.length) {
        const formattedToLeave = allGroups.filter((g) => toLeave.includes(g.group));
        formattedToLeave.forEach((g) => {
          const foundInGroups = groups.find((gr) => gr.group === g?.group);
          if (foundInGroups?.joined) {
            wsSocket?.emit('group:leave', {group: `${decodeURIComponent(foundInGroups?.group)}`});
          }
        });
      }
      const {report: newReportGroup, mainReport: newGroupMainReport, riskReward: newRiskReward} = newGroup?.report ?? {
        report: null,
        mainReport: null,
        riskReward: null,
      };
      const toJoin = [newGroup?.group];
      if (newReportGroup) {
        toJoin.push(newReportGroup);
      }
      if (newGroupMainReport) {
        toJoin.push(newGroupMainReport);
      }
      if (newRiskReward) {
        toJoin.push(newRiskReward);
      }
      if (!toJoin?.length) return;
      const formattedToJoin = allGroups.filter((g) => toJoin.includes(g.group));
      const joinedGroups = formattedToJoin.map((g) => {
        const {group: rbGroup, type: rbType, date} = g;
        const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';

        const groupToJoin = {
          group: `${decodeURIComponent(rbGroup)}`,
        };

        if (date) {
          groupToJoin.date = date ?? today;
        }
        if (currentDate) {
          groupToJoin.date = currentDate;
        }
        wsSocket?.emit(`${scannerType}:join`, groupToJoin);
        return {...g, sentJoin: true};
      });
      dispatch({type: 'EMIT_JOIN', payload: {joinedGroups, leftGroups: toLeave}});
      setCurrentTab(inView);
    }
  }, [inView]);

  const checkIfLoading = () => {
    if (!loadingTab) {
      return;
    }
    if (loadingTab) {
      const currentGroup = allTabs?.[currentTab];
      if (!currentGroup) return;
      const mainGroup = groupCollection?.[currentGroup?.group];
      let joined = mainGroup?.joined;
      if (mainGroup && mainGroup?.report) {
        const {report, mainReport, riskReward} = mainGroup?.report;
        if (report) {
          joined = groupCollection?.[report]?.joined;

        }
        if (mainReport) {
          joined = groupCollection?.[mainReport]?.joined;
        }

        if (riskReward) {
          joined = groupCollection?.[riskReward]?.joined;
        }
      }
      if (joined) {
        setLoadingTab(false);
      }
    }
  };

  useEffect(() => {
    checkIfLoading();
  }, [currentTab, groupCollection]);

  useEffect(() => {
    checkIfLoading();
  }, [groupInView]);

  const handleChangeTab = (index) => {
    if (updatingDate) {
      return;
    }
    // const externalURL = window.location.href;
    // let token = params?.token;
    // if (!token) {
    //   token = localStorage.getItem('scanner-sso');
    // }
    // // window.open(
    // //   `${externalURL}?token=${token}`,
    // //   'popup',
    // //   'toolbar=0,scrollbars=1,statusbar=0,menubar=0,resizable=1,height=500,width=433',
    // // );
    // const tab = tabs[index];
    // const grName = encodeURIComponent(tab?.group);
    // window.open(
    //   `${externalURL}?token=${token}&tab=${grName}`,
    //   '_blank',
    //   'toolbar=0,scrollbars=1,statusbar=0,menubar=0,resizable=1,height=500,width=433',
    // );
    if (currentTab === index) return;
    dispatch({type: 'SET_TAB_IN_VIEW', payload: index});
    setLoadingTab(true);
  };

  return (
    <>
      <ScannerHeader
        name={scannerTitle}
        theme={theme}
        setTheme={setTheme}
        userData={userData}
        showChart={showChart}
        groups={groups}
        currentDate={currentDate}
        wsSocket={wsSocket}
      />
      <Tabs
        onSelect={(index) => {
          handleChangeTab(index);
        }}
        selectedIndex={currentTab}
      >
        <TabList>
          {allTabs?.map((group) => (
            <Tab key={group?.title ?? group?.group} disabled={group?.locked || updatingDate}>
              {group?.locked ? (
                <button
                  type="button"
                  label="open options"
                  onClick={() => {
                    if (group.upcoming) {
                      if (!openUpcomingModal) return;
                      openUpcomingModal();
                      return;
                    }
                    if (!openLockedModal) return;
                    openLockedModal();
                  }}
                >
                  {group?.title ?? group?.group} <span>&nbsp;</span>
                  <FontAwesomeIcon icon={faLock} />
                </button>
              ) : (
                group?.title ?? group?.group
              )}
            </Tab>
          ))}
        </TabList>
        {allTabs?.map((group) => (
          <TabPanel key={group?.title ?? group?.group}>
          {loadingTab ? (
            <div className="w-full h-full min-h-screen flex items-center justify-center">
              <div className="lds-dual-ring-main" />
            </div>
          ) : groupCollection?.[group.group] ? (
            <Scanner
              group={groupCollection[group.group] ?? null}
              groupNumber={groups.length}
              dispatch={dispatch}
              multiScreen={Object.keys(groupCollection || {}).length > 1}
              theme={theme}
              setTheme={setTheme}
              wsSocket={wsSocket}
              openOptionsModal={openOptionsModal}
              openReportModal={openReportModal}
              showReportModal={showReportModal}
              setShowLockedModal={showLockedModal}
              optionsColumns={scannersData?.find((s) => s.slug === params?.id)?.optionsColumns ?? {}}
              getSymbolData={getSymbolData}
              optionsAllowed={scannersData?.find((s) => s.slug === params?.id)?.optionsAllowed ?? false}
              hasReportPopout={scannersData?.find((s) => s.slug === params?.id)?.hasReportPopout ?? false}
              hideHeader={scannersData?.find((s) => s.slug === params?.id)?.hideHeader === true ?? false}
              trimNumbersFromSymbol={
                scannersData?.find((s) => s.slug === params?.id)?.trimNumbersFromSymbol === true ?? false
              }
              alignColumns={scannersData?.find((s) => s.slug === params?.id)?.alignColumns ?? 'left'}
              hidePagination={scannersData?.find((s) => s.slug === params?.id)?.hidePagination ?? {}}
              hideSearch={scannersData?.find((s) => s.slug === params?.id)?.hideSearch ?? {}}
              useSecondaryHeaderTitle={
                scannersData?.find((s) => s.slug === params?.id)?.useSecondaryHeaderTitle === true ?? false
              }
              hideDateSelectorForGroups={
                scannersData?.find((s) => s.slug === params?.id)?.hideDateSelectorForGroups === true ?? false
              }
              allowExportCSV={scannersData?.find((s) => s.slug === params?.id)?.allowExportCSV === true ?? false}
              openLockedModal={openLockedModal}
              groupCollection={groupCollection}
              scannerLayout={scannerLayout}
              groups={groups}
              hasMainReport={hasMainReport}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              updatingDate={updatingDate}
              setUpdatingDate={setUpdatingDate}
              reports={reports}
              currentDate={currentDate}
              inView={inView}
            />
          ) : null}
        </TabPanel>
        ))}
      </Tabs>
      {/* Locked Modal */}
      <Modal isOpen={showLockedModal} onRequestClose={closeLockedModal} style={customStylesLocked}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeLockedModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div className="upgrade-modal">
          <h1>
            To unlock this feature, reach out in the mod chat or{' '}
            <a
              href="https://s3.amazonaws.com/assets.monumenttradersalliance.com/schedule-a-call/dpsup/index.html"
              target="_blank"
              rel="noreferrer"
            >
              click here to schedule a call with our VIP Service Squad
            </a>
            .
          </h1>
          {/* <a
            className="click-here"
            href="https://s3.amazonaws.com/assets.monumenttradersalliance.com/schedule-a-call/dpsup/index.html"
            target="_blank"
            rel="noreferrer"
          >
            Click Here to Upgrade
          </a> */}
        </div>
      </Modal>
      {/* Upcoming Modal */}
      <Modal isOpen={showUpcomingModal} onRequestClose={closeUpcomingModal} style={customStylesUpcoming}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeUpcomingModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div className="upgrade-modal">
          <h1>Feature Coming Soon</h1>
        </div>
      </Modal>
      {/* Trading Chart Modal */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <TradingViewWidget
          symbolData={symbolData}
          symbolToChart={symbolToChart}
          getSymbolData={getSymbolData}
          chartSettings={chartSettings}
          wsSocket={wsSocket}
          groupCollection={groupCollection}
          closeChart={closeChart}
          theme={theme}
          groupToChart={groupToChart}
        />
      </Modal>
      {/* Options Modal */}
      <Modal isOpen={showOptionsModal} onRequestClose={closeOptionsModal} style={customStylesOptions}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeOptionsModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div>
          <OptionView
            optionToView={optionToView}
            groupCollection={groupCollection}
            optionsLayout={scannersData?.find((s) => s.slug === params?.id)?.optionsLayout ?? {}}
          />
        </div>
      </Modal>
      {/* Report Modal */}
      <Modal isOpen={showReportModal} onRequestClose={closeReportModal} style={customStylesReports}>
        <div className="close-modal-wrap flex items-center justify-end p-1">
          <button className="close-modal cursor-pointer" type="button" onClick={closeReportModal}>
            <FontAwesomeIcon className="h-5 w-5" icon={faTimesCircle} />
          </button>
        </div>
        <div>
          <ReportView reportToView={reportToView} groupCollection={groupCollection} />
        </div>
      </Modal>
    </>
  );
};

const optionValuesSame = (scannersData, prev, next) => {
  const id = window.location.pathname.replace('/scanners/', '');
  const optionsLayout = scannersData?.find((s) => s.slug === id)?.optionsLayout ?? {};
  const optionsLayoutValues = Object.values(optionsLayout ?? {});
  if (!optionsLayoutValues?.length) {
    return true;
  }
  const {groupCollection: prevGroupCollection, optionToView: prevOptionToView} = prev ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {groupCollection: nextGroupCollection, optionToView: nextOptionToView} = next ?? {
    groupCollection: null,
    optionToView: null,
  };
  const {group: prevGroup, symbol: prevSymbol} = prevOptionToView ?? {group: null, symbol: null};
  const {group: nextGroup, symbol: nextSymbol} = nextOptionToView ?? {group: null, symbol: null};
  const {data: prevData} = prevGroupCollection?.[prevGroup] ?? {data: null};
  const {data: nextData} = nextGroupCollection?.[nextGroup] ?? {data: null};
  if (!prevData && !nextData) {
    return true;
  }
  const prevSymbolData = prevData?.[prevSymbol];
  const nextSymbolData = nextData?.[nextSymbol];

  if (JSON.stringify(prevSymbolData) !== JSON.stringify(nextSymbolData)) {
    const areSame = optionsLayoutValues.every((l) => {
      const {keys} = l;
      return keys.every(
        (k) => JSON.stringify(prevSymbolData?.[k]?.value) === JSON.stringify(nextSymbolData?.[k]?.value),
      );
    });
    return areSame;
  }
  return true;
};

export default React.memo(TabView, (prev, next) => {
  if (prev?.showLockedModal && next?.showLockedModal) {
    return prev?.showLockedModal === next?.showLockedModal;
  }
  if (prev?.showUpcomingModal && next?.showUpcomingModal) {
    return prev?.showUpcomingModal === next?.showUpcomingModal;
  }
  if (prev?.showOptionsModal && next?.showOptionsModal) {
    return prev.optionToView === next.optionToView && optionValuesSame(next?.scannersData, prev, next);
  }
  if (prev?.modalIsOpen && next?.modalIsOpen) {
    return prev?.groupToChart === next?.groupToChart && prev?.symbolToChart === next?.symbolToChart;
  }
  if (prev?.showReportModal && next?.showReportModal) {
    return JSON.stringify(prev?.reportToView) === JSON.stringify(next?.reportToView);
  }

  const collectionSame = JSON.stringify(prev?.groupCollection) === JSON.stringify(next?.groupCollection);
  const groupsSame = JSON.stringify(prev?.groups) === JSON.stringify(next?.groups);
  const showOptionsModalSame = prev.showOptionsModal === next.showOptionsModal;
  const modalIsOpenSame = prev?.modalIsOpen === next?.modalIsOpen;
  const showLockedModalSame = prev?.showLockedModal === next?.showLockedModal;
  const showReportModalSame = prev?.showReportModal === next?.showReportModal;
  const showUpcomingModalSame = prev?.showUpcomingModal === next?.showUpcomingModal;
  const trimNumbersFromSymbolSame = prev?.trimNumbersFromSymbol === next?.trimNumbersFromSymbol;
  const inViewSame = prev?.inView === next?.inView;
  const currentDateSame = prev?.currentDate === next?.currentDate;

  return (
    collectionSame &&
    groupsSame &&
    showOptionsModalSame &&
    modalIsOpenSame &&
    showLockedModalSame &&
    showUpcomingModalSame &&
    trimNumbersFromSymbolSame &&
    showReportModalSame &&
    inViewSame &&
    currentDateSame
  );
});
// export default TabView;
