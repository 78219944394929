import React, {useState, useEffect, forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import CustomInputDate from './CustomInputDate';
import Toggle from '../Toggles/Toggle';
import {join} from '../../Helpers/formatData';
// import mmlLogo from '../../Icons/mml-logo.png';
// import ScannerLogo from '../../Icons/scanner_image.png';

const moment = require('moment');

const ScannerHeader = ({name, theme, setTheme, userData, showChart, groupCollection, dispatch, showSelector, groups, wsSocket, currentDate}) => {
  // const freeAccess = userData?.free_access;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (groupCollection) {
      const group = Object.values(groupCollection)[0];
      if (group) {
        setSelectedDate(group?.selectedDate);
      }
    }
  }, [groupCollection]);

  const disableDays = (current) => {
    const june = moment().set({year: 2022, month: 4, date: 31}).toDate();
    const day = moment(current).day();
    return day !== 0 && day !== 6 && current >= june;
  };

  const formatDate = (date) => {
    const timestamp = Date.parse(date);
    if (Number.isNaN(timestamp)) return new Date();
    const selectedToMomentDay = moment(date, 'YYYY-MM-DD');
    const june = moment().set({year: 2022, month: 4, date: 31});
    const day = selectedToMomentDay.day();
    // If not weekend and after June 31st, return date
    if (day !== 0 && day !== 6 && !moment(selectedToMomentDay).isBefore(june)) {
      return selectedToMomentDay.toDate();
    }
    return new Date();
  };

  const handleMultiGroupDatePickerChange = (date) => {
    if (!wsSocket) return;

    const newDate = moment(date).format('YYYY-MM-DD');

    if (!groups?.length || !newDate) return;
    groups.forEach((gr) => {
      const {type: groupType, group: groupName} = gr;
      if (!groupType || !groupName) return;
      // const getGroup = groupCollection?.[groupName] ? {...groupCollection[groupName]} : null;
      // if (!getGroup) return;

      const scannerType = groupType ? decodeURIComponent(groupType) : 'tickalert';
      const groupToJoin = {
        group: `${groupName ? decodeURIComponent(groupName) : ''}`,
        date: newDate,
      };
      wsSocket?.emit(`${scannerType}:join`, groupToJoin);
    });
    dispatch({type: 'CHANGE_MULTI_GROUP_DATE', payload: {newDate, groupCollection}});
  };

  const headerTitle = name ? (
    <>
      {/* <img className="w-8 h-8" src={ScannerLogo} alt="Logo" /> */}
      {/* <DarkPoolIcon /> */}
      <h1 className="text-header-color font-bold text-[24px] leading-[28px] ml-2 group-name">{name}</h1>
      <div>
        <div className="flex items-center">
          <DatePicker
            filterDate={disableDays}
            selected={currentDate ? formatDate(currentDate) : formatDate(selectedDate ?? null)}
            onChange={handleMultiGroupDatePickerChange}
            customInput={<CustomInputDate />}
          />
        </div>
      </div>
    </>
  ) : null;

return (
    <div className={`${showChart ? 'chart-visible' : ''} mx-auto scanner-group-header`}>
      <div className="flex justify-between">
        <div className="flex gap-4 items-center">{showSelector ? headerTitle : null}</div>
        <div className="flex gap-7 pr-5 dark-mode-toggle">
          <div className="flex items-center">
            <Toggle theme={theme} setTheme={setTheme} />
          </div>
        </div>
      </div>
      {/* <div className="mt-2">
        <small>To change scanner alert subscriptions, pleace go to <u><a href="/" target="_blank" rel="noreferrer"> Alert Settings</a></u>.</small>
      </div> */}
    </div>
  );
};

export default ScannerHeader;
